<template>
  <div>
    <vs-button @click="deleteCaches" class="float-right mb-5">Caches löschen</vs-button>
      <label>Jahr:</label>
      <select v-model="selectedYear" style="background-color:lightgrey" class="mr-4">
          <option>2023</option>
          <option>2024</option>
          <option>2025</option>
      </select>
      <label>Quartal:</label>
      <select v-model="selectedQuarter" style="background-color:lightgrey">
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
      </select>
      <vs-button color="dark" type="filled" icon="search" size="small" @click="reloadAllUserData()">Auswählen</vs-button>

    <present-user-bonus-quickview v-for="user in users" ref="userBonusQuickview" :user="user" :year="selectedYear" :quarter="selectedQuarter" v-if="user.employee_settings && user.employee_settings.bonusprogram">

    </present-user-bonus-quickview>

  </div>


</template>

<script>
import PresentUserBonusQuickview from "@/views/dashboard/PresentUserBonusQuickview";
import ApiService from "@/api";
import * as qs from "qs";
export default {
  name: "BonusStatistic",
  components: {PresentUserBonusQuickview},
  data() {
    return {
      // Line Charts
      users: {},
      selectedYear: null,
      selectedQuarter: null,
    }
  },
  created() {
    this.fetchUsers()
  },
  methods: {
      reloadAllUserData(){
          for (let i = 0; i < this.users.length; i++) {
              this.$refs.userBonusQuickview[i].fetchData()
          }
      },
    fetchUsers() {

      let queryParams = {};

      this.$vs.loading()
      ApiService.get('users', {
        params: queryParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.users = response.data.result;
        this.$vs.loading.close()
      }).catch(response => {
      })
    },
    deleteCaches() {

      let queryParams = {};

      this.$vs.loading()
      ApiService.delete('user-bonus-target/bonus-calculation-caches').then(response => {
        this.$vs.loading.close()
      }).catch(response => {
      })
    },
  },
}
</script>

<style scoped>

</style>